import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { Text } from "./Typography";
import Button from "./Button";
import SearchBar from "./Search/SearchBar";
import { Link, Loader } from "./Utils";
import { Avatar } from "./User";
import { Grid, Cell, Border } from "./Layout";
import Navburger from "./Navburger";
import { Actions } from "@voxmarkets/vox-reducers";
import UiActions from "./../redux/actions";
import { selectIsAuthenticationCompleted } from "./../redux/selectors";
import Popover from "./Popover";
import { WatchlistMargin } from "./PageLayout";

import { Navigation, MobileNavigation } from  "features/navigation/components/MainNavigation";

const Main = styled.header`
  background-color: ${props => props.transparent ? 'transparent' :'#0f293d' };
  ${props => props.theme && props.theme === "GCI" && 'background-color:#fff;'}
  height: 118px;
`;

const HeaderGrid = styled(Grid)`
  height:74px;
`;

const Logo = styled.img`
  max-height:50px;
  display:inline-block;
  margin-right: 24px;
  `;

const Ico = styled(Link)`
  padding: 5px 10px;
  display: inline-block;
`;




//-----------------------
//-----------------------
//-----------------------

function AuthNavigation(props) {
  return (
    <Grid>
      <Cell col={6}>
        <Button block="true" invert onClick={props.onLoginClick}>
          Log In
        </Button>
      </Cell>
      <Cell col={6}>
        <Button as="a" block="true" href="/registration/" primary>
          Join
        </Button>
      </Cell>
    </Grid>
  );
}
//-----------------------
//-----------------------
//-----------------------

function User({ handleLogOut }) {
  const { name, avatar } = useSelector((state) => state.userData);
  return (
    <Grid middle>
      <Cell fill>
        <Link href={`/me/`} block="true">
          <Text white bold right>
            {name}
          </Text>
        </Link>
      </Cell>
      <Cell fit>
        <Popover
          atClick
          label={
            <Avatar src={avatar} size="55px" />
          }
        >
        </Popover>
      </Cell>
    </Grid>
  );
}


//-----------------------
//-----------------------
//-----------------------


//-----------------------
//-----------------------
//-----------------------

const GCIWrapper = styled.div`
  color: ${ props => props.theme.color.gci };
  border-top: 8px solid ${ props => props.theme.color.gci };
  
  box-shadow: 0 0 12px #666;
  h1 {
  margin: 0;
  padding: 0;
  }
  ${Text} {
  color: ${ props => props.theme.color.gci };
  }
`;

const GCILink = styled(Link)`
color: ${ props => props.theme.color.gci };
font-weight: ${ props => props.active ? 'bold' : 'normal' };
padding: 0 12px;
`;

const GCIHeader = ({ isWatchlist }) => {
  const { categorySlug } = useParams();
  return <GCIWrapper>
    <Main transparent theme="GCI">
      <WatchlistMargin isWatchlist={isWatchlist}>
        <Border>
          <HeaderGrid middle>
            <Cell fit>
              <Link href="/gci/">
                <h1>
                  <img src="/gci-logo.png" width="206" height="118" />
                </h1>
              </Link>
            </Cell>
            <Cell fill>
              <div>
                <GCILink href="/gci/ideas/" active={categorySlug ==="ideas" }>IDEAS</GCILink>
                <GCILink href="/gci/updates/" active={categorySlug ==="updates" } >UPDATES</GCILink>
                <GCILink href="/gci/performance/" active={categorySlug ==="performance" }>PERFORMANCE</GCILink>
                <GCILink href="/gci/podcasts/" active={categorySlug ==="podcasts" }>PODCASTS</GCILink>
              </div>
            </Cell>
            <Cell fit>
              <Link href="/"><img src="/vox-profile-logo.png" alt="Vox Markets" width="48" height="48" /></Link>
            </Cell>
          </HeaderGrid>
          
        </Border>
      </WatchlistMargin>
    </Main>
  </GCIWrapper>
}


export default function Header({ isWatchlist, hideSubnav, transparent, theme }) {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const IsAuthenticationCompleted = useSelector(
    selectIsAuthenticationCompleted
  );

  const handleLogOut = (e) => {
    typeof window !== "undefined" && window.sessionStorage.clear();
    dispatch(Actions.authentication.logout());
    window.location.href = "https://www.voxmarkets.co.uk/restful?type=logout";
  };

  const handleLogIn = (e) => {
    e.preventDefault();
    dispatch(UiActions.loginOverlay.show(true));
  };

  
  const { loggedIn, loggingIn } = authentication;

  if(theme && theme === "GCI"){
    return <GCIHeader isWatchlist={isWatchlist} />
  }

  return (
    <>
      <Main transparent={transparent} theme={theme}>
        <WatchlistMargin isWatchlist={isWatchlist}>
          <Border>
            <HeaderGrid middle>
              <Cell fill>
                <Link href="/" title="Vox Markets Home Page">
                  <Logo src='/logo.png' alt="Vox Markets Logo" width="150" height="50" />
                </Link>
    </Cell>
    {loggingIn && (
      <Cell fit lgDisplay  lgCol={3}>
        <Loader size={20} />
      </Cell>
    )}
    
    {!loggedIn && IsAuthenticationCompleted && (
      <Cell lgDisplay lgCol={3}>
        <AuthNavigation onLoginClick={handleLogIn} />
      </Cell>
    )}
    <Cell lgCol={3} smFill mdFill>
      <SearchBar />
    </Cell>
    <Cell fit smDisplay mdDisplay>
      <Navburger>
        <MobileNavigation onLogOut={handleLogOut}>
          <AuthNavigation onLoginClick={handleLogIn} />
        </MobileNavigation>
      </Navburger>
    </Cell>
            </HeaderGrid>
            <Navigation onLogOut={handleLogOut} isWatchlist />
          </Border>
        </WatchlistMargin>
      </Main>
      
    </>
  );
}
